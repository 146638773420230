<template>
    <div>
      <header-slot />
      <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: 'sales-made-quality-add-change' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          >Add/Change Service
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'sales-made-quality-add-sale' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          >Add Sale</b-nav-item
        >
      </b-nav>
  
      <b-card
        no-body
        class="border-top-primary border-3 border-table-radius px-0"
      >
        <router-view :key="$route.name" />
      </b-card>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style></style>
  